import playStore from "./assets/Google-play.svg";
import appleStore from "./assets/app-store.svg";
import linkedin from "./assets/linkedin.svg";
import twitter from "./assets/twitter.svg";
import facebook from "./assets/facebook.svg";
import instagram from "./assets/instagram.png";
import arcarga from "./assets/arcarga.svg";
import placeholder from "./assets/car.png";
import { useState, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import toast, { Toaster } from "react-hot-toast";

export default function App() {
  const [input, setInput] = useState("");
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [Autoplay()]);

  useEffect(() => {
    if (emblaApi) console.log(emblaApi.slideNodes());
  }, [emblaApi]);

  const slidesData = [
    {
      imgSrc: placeholder,
      heading: "Quick & Effortless Repair Request",
      text: "Easily Submit and manage repairs requests with a few taps. Aucarga puts you in control, making vehicle maintenance a breeze",
    },
    {
      imgSrc: placeholder,
      heading: "Personalized Service Recommendation",
      text: "Recieve personalized service recommendations based on your vehicle's make, model, and maintenance history",
    },
    {
      imgSrc: placeholder,
      heading: "Real-time Service Tracking",
      text: "Stay informed througout the repair process with real-time service tracking. Track the progress of your vehicle's maintenance easily",
    },
    {
      imgSrc: placeholder,
      heading: "Secure and Convenient Payments",
      text: "Easily complete transactions within the app after the service is done, ensuring a seamless and trusthworthy payment process for all your vehicle maintenance needs.",
    },
  ];

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.trim() === "") {
      toast.error("Please input your email.");
    } else if (!validateEmail(input)) {
      toast.error("Please add a valid email.");
    } else {
      toast.success("Submitted!");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#070707f2] lg:p-8 p-5">
      {/* <header className="lg:w-[4%] md:w-[5%] w-[10%] h-auto">
        <img src={logo} alt="Logo" className="w-full h-full" />
      </header> */}
      <section className="flex mx-2 md:6 lg:mx-16 justify-center items-center flex-col lg:flex-row">
        <section className="flex gap-1 flex-col flex-1 text-[#dedede] ">
          <img
            src={arcarga}
            alt="Arcaga Logo"
            width={500}
            className="mt-4 lg:mt-0"
          />
          <section className="py-6 lg:py-0">
            <h1 className="lg:text-[40px] text-[26px] md:text-[60px] font-bold gradient-text">
              Coming Soon
            </h1>
            <p className="lg:text-[18px] text-[19px] font-semibold">
              Your One-Stop App for Effortless Auto Maintenance & Repair.{" "}
            </p>
            <p className="mt-3 lg:text-[18px] text-[16px] text-white">
              Register for free, Compare, Select, and Secure Your Appointment{" "}
              <br />
              Anywhere, Anytime, at Your Home or Preferred Garage
            </p>
            <form
              data-static-form-name="email"
              className="flex mt-6 w-full border rounded-lg max-w-[500px]"
            >
              <input
                type="email"
                placeholder="example@gmail.com"
                required
                value={input}
                onChange={handleChange}
                className="bg-none bg-transparent outline-none focus:none px-2 py-3 w-full placeholder:text-[#dedede]"
              />
              <button
                type="submit"
                onClick={handleSubmit}
                className="full bg-[white] text-black rounded-lg w-[150px] p-2 transition-all hover:opacity-90 transistion-300  text-[16px] font-semibold"
              >
                Notify Me
              </button>
            </form>
            <div className="flex items-center mt-8 gap-5">
              <img
                src={playStore}
                alt="Google play logo"
                className="w-[150px] h-auto cursor-pointer"
              />
              <img
                src={appleStore}
                alt="Apple store logo"
                className="w-[150px] h-auto cursor-pointer"
              />
            </div>
            <section className="mt-10">
              <div className="flex lg:flex-row flex-col gap-5 items-center justify-between">
                <div className="flex items-center gap-5 lg:mb-0 md:mb-0 mb-10">
                  <a href="https://www.linkedin.com/company/aucarga/">
                    <img
                      src={linkedin}
                      alt="LinkedIn icon"
                      className="w-[20px] h-auto cursor-pointer"
                    />
                  </a>
                  <a href="http://x.com/aucarga">
                    <img
                      src={twitter}
                      alt="Twitter icon"
                      className="w-[20px] h-auto cursor-pointer"
                    />
                  </a>
                  <a href="https://www.facebook.com/company/aucarga/">
                    <img
                      src={facebook}
                      alt="facebook icon"
                      className="w-[20px] h-auto cursor-pointer"
                    />
                  </a>
                  <a href="https://www.instagram.com/aucarga" _blank>
                    <img
                      src={instagram}
                      alt="Instagram icon"
                      className="w-[20px] h-auto cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </section>
          </section>
        </section>

        <section className="embla mt-auto hidden lg:flex md:flex justify-center items-center flex-col flex-1 text-[#dedede]">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container text-white">
              {slidesData.map((slide, index) => (
                <div className="embla__slide" key={index}>
                  <div className="text-white">
                    <h1 className="text-6xl mt-60 font-bold">
                      {slide.heading}
                    </h1>
                    <p className="text-2xl py-4">{slide.text}</p>
                    <img src={slide.imgSrc} width="600px" alt="Illustration" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>

      <Toaster />
    </div>
  );
}
